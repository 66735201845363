<template>
    <div class="page" id="reorganize">
        <div class="bg-white">
            <el-form class="query-form searchForm" size="small" ref="inputForm" :model="inputForm" label-width="100px">
                <el-form-item label="比对模型" prop="modelLabel">
                    <el-select v-model="inputForm.modelLabel"
                               placeholder="请选择比对模型"
                               style="width: 100%"
                    >
                        <el-option
                                v-for="item in contrastModelList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="输入搜索" prop="keyWord">
                    <el-input v-model="inputForm.keyWord"
                              placeholder="请输入关键字(限20字)"
                              @keyup.enter.native="getDataList(1,1)"
                              maxlength="20" clearable></el-input>
                </el-form-item>
                <el-form-item label="开始时间" prop="startTime" class="dateBox">
                    <el-date-picker
                            v-model="inputForm.startTime"
                            type="datetimerange"
                            style="width: 100%;"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间" prop="endTime" class="dateBox">
                    <el-date-picker
                            v-model="inputForm.endTime"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            style="width: 100%;"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="定时启动时间" prop="timedTime" class="dateBox">
                    <el-date-picker
                            v-model="inputForm.timedTime"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            style="width: 100%;"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <div class="flex_b_c">
                    <div>
                        <el-button size="small" type="primary" @click="getDataList(1,1)"
                                   icon="el-icon-search">查询
                        </el-button>
                        <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
                    </div>
                    <!--            <el-form-item class="f_r">-->
                    <div>
                        <el-button type="primary" size="small"
                                   v-show="hasPermissionButton(`dyyg:image:contrast:add`)"
                                   icon='el-icon-plus' @click="addData(0)">新增
                        </el-button>
                        <el-button icon="el-icon-delete" size="small" type="danger" @click="deleteDate()"
                                   v-show="hasPermissionButton(`dyyg:image:contrast:delete`)">批量删除
                        </el-button>
                    </div>
                </div>
            </el-form>
        </div>

        <div class="bg-white" style="margin-top: 10px">
            <el-table
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable"
                    height="calc(100vh - 360px)"
                    class="table"
                    :row-key="'id'"
                    :header-cell-style="{background:'#F9DFDF'}"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column prop="jobName" label="任务名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="modelLabel" label="比对模型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        图片大小比对
                    </template>
                </el-table-column>
                <el-table-column prop="modelStartTime" label="开始时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.modelStartTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="modelEndTime" label="结束时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.modelEndTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="modelStartUpTime" label="定时启动时间" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.modelStartUpTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column prop="remake" label="任务描述" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text"
                                   @click="addData(1, scope.row,scope.$index)"
                                   v-show="hasPermissionButton(`dyyg:image:contrast:detail`)">
                            详情
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   v-if="scope.row.modelState == 1"
                                   @click="addData(2, scope.row)"
                                   v-show="hasPermissionButton(`dyyg:image:contrast:edit`)">
                            修改
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   @click="deleteDate(scope.row)"
                                   v-show="hasPermissionButton(`dyyg:image:contrast:delete`)">
                            删除
                        </el-button>
                        <el-button style="margin-bottom: 10px" size="mini" type="text"
                                   v-if="scope.row.modelState == 1"
                                   @click="startUp(scope.row)"
                                   v-show="hasPermissionButton(`dyyg:image:contrast:startUp`)">
                            启动
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="current"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="size"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>
        <image-comparison-add ref="imageComparisonAdd" @refreshList="getDataList()"></image-comparison-add>
    </div>
</template>

<script>
import ImageComparisonAdd from "@/views/modules/dyyg/imageProcessing/ImageComparisonAdd.vue";

export default {
    name: "ImageComparison",
    components: {ImageComparisonAdd},
    data() {
        return {
            inputForm: {
                modelLabel: '图片大小比对',
                keyWord: '',

                startTime: [],
                modelStartTimeStart: '',
                modelStartTimeEnd: '',

                endTime: [],
                modelEndTimeStart: '',
                modelEndTimeEnd: '',

                timedTime: [],
                modelStartUpTimeStart: '',
                modelStartUpTimeEnd: '',
            },
            contrastModelList: [
                {
                    label: '图片大小比对',
                    value: '图片大小比对',
                },
            ],

            dataListSelect: [],
            loading: false,
            dataList: [],

            pageNo2: 0,
            current: 1,
            size: 10,
            total: 0,
        }
    },

    mounted() {
        this.getDataList()
    },

    methods: {
        //获取列表数据
        getDataList(type, dividePage) {
            if (type == 1) {
                this.current = 1
            }
            if (dividePage == 1) {
                this.$refs.multipleTable.clearSelection()
            }
            let searchData = JSON.parse(JSON.stringify(this.inputForm))
            if (searchData.startTime && searchData.startTime.length != 0) {
                searchData.modelStartTimeStart = searchData.startTime[0]
                searchData.modelStartTimeEnd = searchData.startTime[1]
            } else {
                searchData.modelStartTimeStart = ''
                searchData.modelStartTimeEnd = ''
            }
            this.$delete(searchData, 'startTime')
            if (searchData.endTime && searchData.endTime.length != 0) {
                searchData.modelEndTimeStart = searchData.endTime[0]
                searchData.modelEndTimeEnd = searchData.endTime[1]
            } else {
                searchData.modelEndTimeStart = ''
                searchData.modelEndTimeEnd = ''
            }
            this.$delete(searchData, 'endTime')
            if (searchData.timedTime && searchData.timedTime.length != 0) {
                searchData.modelStartUpTimeStart = searchData.timedTime[0]
                searchData.modelStartUpTimeEnd = searchData.timedTime[1]
            } else {
                searchData.modelStartUpTimeStart = ''
                searchData.modelStartUpTimeEnd = ''
            }
            this.$delete(searchData, 'timedTime')
            this.loading = true;
            this.$axios(this.api.original.dyygimgmodelList, {
                ...searchData,
                current: this.current,
                size: this.size,
            }, 'post').then(res => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    this.total = parseInt(res.data.total)
                    if (this.dataList.length == 0 && this.current > 1) {
                        this.pageNo--
                        this.getDataList()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //num 0新增 1详情 2修改
        addData(num, row) {
            this.$refs.imageComparisonAdd.init(num, row)
        },

        deleteDate(row) {
            if (!row && !this.dataListSelect.length) {
                this.$message.warning('请至少选择一条数据')
                return
            }
            let ids
            if (row) {
                ids = [row.id]
            } else {
                ids = this.dataListSelect.map(item => {
                    return item.id
                })
            }
            this.$confirm(`确认删除所选数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.original.dyygimgmodelRemoveById, ids, 'post').then(data => {
                    if (data && data.status) {
                        this.$message.success('删除成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        //启动
        startUp(row) {
            this.$confirm(`确认启动该数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.original.dyygimgmodelEnable, {modelId: row.id}, 'get').then(data => {
                    if (data && data.status) {
                        this.$message.success('启动成功')
                        this.getDataList('', 1)
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', 2);
        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },
    },
}
</script>

<style lang="scss" scoped>
.bg-white {
  overflow-y: auto !important;
}

.searchForm {
  .el-form-item--small.el-form-item {
    display: inline-block;
    width: 24.5%;
  }

  .dateBox.el-form-item--small.el-form-item {
    display: inline-block;
    width: 49%;
  }
}
</style>